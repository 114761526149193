<template>
  <KCourse loader-class="MBcont" course-id="0" title="Шарнирная кукла" :items="[]">

  <div id="script"></div>
  <KPdf :src="getPdfSrc"/>
  </KCourse>
</template>

<script>
import KPdf from "@/components/Widgets/KPdf";
import {ref, watchEffect, onMounted} from "vue";
import {useRouter} from "vue-router";
import { loadVideo } from "@/service/api";
import KCourse from "@/components/Widgets/KCourse";
export default {
  name: "Bjd",
  components: {
    KPdf,
    KCourse
  },
  setup() {
    const router = useRouter();
    const otp = ref(null)
    const videoId = ref(null)


    const loadOtp = (_videoId) => {

      return loadVideo(_videoId)
        .then(response => {
          otp.value = response.data.data.otp
          videoId.value = _videoId
        })
        .catch(error => {
          //todo add error view
          console.log(error)
          if (error.response.status === 404) {
            router.push("/404")
          }
        })
    }

    onMounted( () => {
      var my_awesome_script = window.document.createElement('script');
      my_awesome_script.setAttribute('src','//dev.vdocipher.com/playerAssets/1.6.10/vdo.js');
      let element = window.document.head
      element.append(my_awesome_script)
    })

    return {otp, loadOtp, videoId};
  },
  computed: {
    getPdfSrc() {// eslint-disable-line
      this.setClickHandler()
      let fullPath = "/files/BJD/BJD_1chapter.pdf"

      if (this.doesFileExist(fullPath)) {
        return fullPath
      } else {
        this.$router.push("/404")// eslint-disable-line
      }
    },
  },
  created() {


    watchEffect(() => {
      if (this.otp === null || this.otp === undefined) {
        return
      }
      let videoWidth = window.innerWidth < 800 ? window.innerWidth : 800
      let videoId = this.videoId
      this.$swal.fire({
        width: videoWidth + 'px',
        background: '#eae2e0',
        html: '<div id="video' + videoId + '"></div>',
        showCloseButton: true,
        showConfirmButton: false,
        // closeButtonHtml: "",
        didOpen: () => {
          new VdoPlayer({ // eslint-disable-line
            otp: this.otp,
            playbackInfo: btoa(JSON.stringify({
              videoId: videoId
            })),
            theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
            // the container can be any DOM element on website
            container: document.querySelector("#video" + videoId),
          });
        },
        didDestroy: () => {
          this.otp = null
        }
      })
    })
  },
  methods: {
    setClickHandler() {
      if (!window.addEventListener) {
        // IE8 support (could also use an addEventListener shim)
        window.attachEvent('onmessage', this.handleMessage);
      } else {
        window.addEventListener('message', this.handleMessage, false);
      }


    },
    handleMessage(event) {
      if (event.data.videoPopup) {
        let data = event.data.videoPopup.split("=")
        this.loadOtp(data[1])
      }

    },
    doesFileExist(urlToFile) {
      var xhr = new XMLHttpRequest();
      xhr.open('HEAD', urlToFile, false);
      xhr.send();

      if (xhr.status == "404") {
        return false;
      } else {
        return true;
      }
    },
  },
}
</script>